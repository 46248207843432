<script>
import Layout from "../layouts/auth";
import {mapActions, mapState} from "vuex";
import {minLength, required} from "vuelidate/lib/validators";

/**
 * Login component
 */
export default {
  components: { Layout },
  data() {
    return {
      preloader: false,
      username: "",
      password: "",
      submitted: false,
      iconPath: require(process.env.VUE_APP_ICON_PATH),
      orgName: process.env.VUE_APP_ORG_NAME
    };
  },
  computed: {
    ...mapState("auth", ["status"]),
    ...mapState({
      alert: state => state.alert
    })
  },
  validations: {
    username: { required },
    password: { required, minLength: minLength(10) }
  },
  created() {
    // reset login status
    this.logout();
  },
  methods: {
    ...mapActions("auth", ["login", "logout"]),
    filteredAlert() {
      if (this.alert.type === 'alert-danger') {
        return 'Podano nieprawidłowe dane do logowania (błędny login lub hasło).';
      }

      return this.alert.message;
    },
    handleSubmit() {
      this.submitted = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const { username, password } = this;
        if (username && password) {
          this.login({ username, password });
        }
      }
    }
  }
};
</script>

<template>
  <Layout>

    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-soft-primary">
            <div class="row">
              <div class="col-7">
                <div class="text-primary p-4">
                  <h5 class="text-primary">Witaj!</h5>
                  <p>Zaloguj się do aplikacji dla opiekunów.</p>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img src="@/assets/images/vector_children0.png" alt class="img-fluid" />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <router-link tag="a" to="/">
                <div class="avatar-md profile-user-wid mb-4">
                  <span class="avatar-title rounded-circle bg-white">
                    <img :src="iconPath" alt height="52" />
                  </span>
                </div>
              </router-link>
            </div>

            <div v-if="alert.message" :class="'alert ' + alert.type">{{ filteredAlert() }}</div>

            <b-form class="p-2" @submit.prevent="handleSubmit">
              <b-form-group id="input-group-1" label="E-mail" label-for="input-1">
                <b-form-input
                  id="input-1"
                  v-model="username"
                  type="text"
                  placeholder="Wpisz e-mail"
                  :class="{ 'is-invalid': submitted && $v.username.$error }"
                ></b-form-input>
                <div v-if="submitted && !$v.username.required" class="invalid-feedback">E-mail jest wymagany</div>
              </b-form-group>

              <b-form-group id="input-group-2" label="Hasło" label-for="input-2">
                <b-form-input
                  id="input-2"
                  v-model="password"
                  type="password"
                  placeholder="Wpisz hasło"
                  :class="{ 'is-invalid': submitted && $v.password.$error }"
                ></b-form-input>
                <div v-if="submitted && $v.password.$error" class="invalid-feedback">
                  <span v-if="!$v.password.required">Hasło jest wymagane.</span>
                  <span v-if="!$v.password.minLength">Hasło musi być dłuższe niż 10 znaków.</span>
                </div>
              </b-form-group>
              <br />
              <div class="mt-3">
                <b-button type="submit" variant="primary" class="btn-block">Zaloguj mnie</b-button>
              </div>
              <div class="mt-4 text-center">
                <router-link tag="a" to="/forgot-password" class="text-muted">
                  <i class="mdi mdi-lock mr-1"></i> Zapomniałeś hasła?
                </router-link>
              </div>
            </b-form>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="mt-5 text-center">
          <p>

          <p>
            © {{new Date().getFullYear()}} {{ orgName }}
            <i class="mdi mdi-heart text-danger"></i>
          </p>
        </div>
        <!-- end row -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style lang="scss" module></style>
